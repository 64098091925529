import { InfoCircleOutlined } from "@ant-design/icons";
import { ThemeContext } from "@src/pages/_app";
import { SP } from "@styled-components/STags";
import { useContext } from "react";

interface BasicHintProps {
  children: React.ReactNode;
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  error?: boolean;
  mt?: number;
  mr?: number;
  ml?: number;
  mb?: number;
  background?: string;
  serenity?: boolean;
  color?: string;
  noBackground?: boolean;
}

const BasicHint: React.FunctionComponent<BasicHintProps> = ({
  children,
  mt,
  ml,
  mr,
  mb,
  title,
  background,
  icon,
  error,
  serenity,
  color,
  noBackground
}) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const textColor =
    (noBackground && appModeTheme?.colorSchema === "light") ||
    (theme.key === "werder" && noBackground)
      ? appModeTheme?.secondLayerTextColor
      : color || theme.palette.primaryTextColor;

  return (
    <div
      style={{
        border: `${serenity ? 1 : 3}px ${serenity ? "solid" : "solid"} ${
          error ? "#D60B52" : theme.palette.primary
        }`,
        padding: "1.12rem 1.5rem",
        color: textColor,
        borderRadius: "1rem",
        marginTop: `${mt}rem`,
        marginBottom: `${mb}rem`,
        marginLeft: `${ml}rem`,
        marginRight: `${mr}rem`,
        height: "fit-content",
        maxWidth: 600,
        background: noBackground ? "transparent" : theme.palette.primary
      }}
    >
      {title && (
        <h3 style={{ color: textColor }}>
          {icon || <InfoCircleOutlined />}
          &nbsp;&nbsp;{title}
        </h3>
      )}

      <SP style={{ color: textColor }}>{children}</SP>
    </div>
  );
};

export default BasicHint;
